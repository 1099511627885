<template>
  <div>
    <div class="row" v-show="phase == 1">
      <div class="col-md-4">
        <!-- <div class="form-group">
          <label for>Especialidad</label>
          <SelectSpecialty
            ref="selSpe"
            v-model="specialty_id"
          ></SelectSpecialty>
        </div> -->
        <div class="form-group">
          <label for>Doctor</label>
          <SelectEmployee
            :specialties="specialty_id"
            ref="selEmp"
            v-model="employee_id"
            @input="
              getSchedule();
              $emit('employeeInterval', $refs.selEmp.val.schedule_interval);
              $emit('changeEmployee', $event);
            "
          ></SelectEmployee>
        </div>
        <div class="form-group">
          <label for="">Grupo</label>
          <select
            class="custom-select"
            v-model="group"
            @change="
              getSchedule();
              $emit('changeGroup', $event.target.value);
            "
          >
            <option value="1">Sillon 1</option>
            <option value="2">Sobre agendamiento</option>
          </select>
        </div>
        <slot></slot>
        <!-- <div class="form-group">
          <label for>Duracion</label>
          <SelectMinutes v-model="duration"></SelectMinutes>
        </div> -->
      </div>
      <div class="col">
        <table class="table table-sm">
          <thead>
            <tr>
              <th class="text-center">
                <a @click.prevent="lastWeek()" class="btn btn-sm" href="#">
                  <i class="fa fa-angle-left"></i>
                </a>
              </th>
              <th colspan="5"></th>
              <th class="text-center">
                <a @click.prevent="nextWeek()" class="btn btn-sm" href="#">
                  <i
                    class="fa
                   fa-angle-right"
                  ></i>
                </a>
              </th>
            </tr>
            <tr>
              <th v-for="(l, index) in weekDates" :key="index">{{ l.date }}</th>
            </tr>
            <tr>
              <th>Domingo</th>
              <th>Lunes</th>
              <th>Martes</th>
              <th>Miercoles</th>
              <th>Jueves</th>
              <th>Viernes</th>
              <th>Sabado</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-for="(l, i) in horary" :key="i">
                <a
                  v-for="(l1, i1) in l"
                  :key="i1"
                  class="d-block mb-2"
                  :class="{ 'bg-dark text-white': l1.selected }"
                  @blur="l1.selected = false"
                  href="javascript:void(0)"
                  @click.prevent="
                    $emit('timeSelected', l1.datetime);
                    l1.selected = true;
                    $forceUpdate();
                  "
                >
                  {{ l1.time }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" v-show="phase == 2">
      <div class="col-md-2">
        <button class="btn btn-sm btn-primary" @click="reset()">
          <i class="fa fa-angle-left"></i> Volver
        </button>
      </div>
      <div class="col-md-6">
        <form @submit.prevent="save()">
          <div class="form-group">
            <label for>Fecha</label>
            <p class="form-control">{{ quote_time }}</p>
          </div>
          <div class="form-group">
            <label for>Cliente</label>
            <SelectClient ref="selCli" v-model="client_id"></SelectClient>
          </div>
          <div class="form-group">
            <label for>Comentario</label>
            <app-textarea v-model="commentary"></app-textarea>
          </div>
          <app-button-submit></app-button-submit>
        </form>
      </div>
      <!-- <div class="col-md-4">
        <pre>{{ plans }} </pre>
      </div>-->
    </div>
    <div class="row" v-if="phase == 3">
      <div class="col">
        <div class="alert alert-success">
          <h4>AGENDADO</h4>
          <p>
            <b>Doctor:</b>
            {{ $refs.selEmp.val.name }}
          </p>
          <p>
            <b>Cliente:</b>
            {{ $refs.selCli.val.name }}
          </p>
          <p>
            <b>Fecha:</b>
            {{ quote_time }}
          </p>
          <slot></slot>
        </div>
      </div>
    </div>
    <!-- <button @click.prevent="getSchedule()">get</button>
    <pre>{{list}} </pre>-->
  </div>
</template>

<script>
import SelectEmployee from "../../admin-module/employees/Select";
import moment from "moment";
import { mapState } from "vuex";
import { DentalClinicService } from "../service";
import { ServiceFactory } from "../../service-module/service";
import SelectClient from "../../store-module/clients/Select";

moment.locale("es");

// class oTimeDeft {
//   constructor() {
//     this.tm_start = undefined;
//     this.tm_start_time = undefined;
//     this.tm_end = undefined;
//     this.day_of_week = undefined;
//     this.color = undefined;
//     this.name = undefined;
//     this.ini_schedule = undefined;
//     this.end_schedule = undefined;
//     this.ini_time = undefined;
//     this.end_time = undefined;
//     this.count_quotes = undefined;
//   }
// }

// let fGetSchedule = function(
//   aSchedule = [new oTimeDeft()],
//   day = "",
//   time = ""
// ) {
//   let aResult = aSchedule.filter(x => x.datetime == time && x.dayOfWeek == day);
//   return aResult.length > 0 ? aResult[0] : undefined;
// };

export default {
  components: {
    // SelectMinutes,
    SelectEmployee,
    // TdTimeView,
    SelectClient
    // SelectSpecialty
  },
  props: {
    p_client_id: {},
    treatment_plan_id: {}
  },
  data: () => ({
    employee_id: null,
    duration: null,
    ini_time: null,
    end_time: null,
    currentTime: null,
    list: [],
    horary: [],
    weekDates: [],
    changesTime: 1,
    timeTobegins: null,
    timeToEnd: null,
    phase: 1,
    quote_time: null,
    client_id: null,
    commentary: null,
    plans: null,
    product_id: null,
    group: 1,
    preventDuration: 0
    // specialty_id: null
  }),
  computed: {
    ...mapState({
      clientConfig: s => s.config.client,
      specialty_id: s => s.config.dental.treatments.specialty_id
    })
  },
  watch: {
    // employee_id() {
    //   this.getSchedule();
    // },
    // duration() {
    //   this.getSchedule();
    // },
    changesTime() {
      this.getSchedule();
    }
  },
  created() {
    this.currentTime = moment(moment().format("YYYYMMDD"), "YYYYMMDD");
    this.updateTimes(this.currentTime);
  },
  methods: {
    setEmployeeId(id) {
      this.$refs.selEmp.setValueFromId(id);
      this.currentTime = moment(moment().format("YYYYMMDD"), "YYYYMMDD");
      this.updateTimes(this.currentTime);
      this.employee_id = id;
      // this.getSchedule();
    },
    /**
     * @param oTimeDeft time
     */
    setTime(time) {
      this.phase = 2;
      this.quote_time = time.tm_start;
      if (this.p_client_id) this.$refs.selCli.setValueFromId(this.p_client_id);
    },
    updateTimes() {
      let ini_time = this.currentTime
        .clone()
        .add(-this.currentTime.day(), "days");
      let end_time = ini_time.clone().add(7, "days");
      this.ini_time = ini_time.format("YYYY-MM-DD");
      this.end_time = end_time.format("YYYY-MM-DD");
      this.weekDates = [];
      for (let index = 0; index < 7; index++) {
        this.weekDates.push({
          day: ini_time.day().toString(),
          date: ini_time.format("DD MMMM")
        });
        ini_time.add(1, "days");
      }
    },
    save() {
      DentalClinicService.saveQuote({
        client_id: this.client_id,
        employee_id: this.employee_id,
        product_id: this.product_id,
        entity_id: this.entity_id,
        ini_date: this.quote_time,
        commentary: this.commentary,
        specialty_id: this.specialty_id,
        treatment_plan_id: this.treatment_plan_id,
        end_date: moment(this.quote_time)
          .add(this.duration, "minutes")
          .format("YYYY-MM-DD HH:mm")
      }).then(res => {
        this.phase = 3;
        this.$emit("submitted", res);
      });
    },
    setSpecialty(specialty) {
      this.$refs.selSpe.setValue(specialty);
      // this.specialty = specialty;
    },
    nextWeek() {
      this.currentTime.add(7, "days");
      this.changesTime++;
      this.updateTimes();
    },
    lastWeek() {
      this.currentTime.add(-7, "days");
      this.changesTime++;
      this.updateTimes();
    },
    getGroup() {
      return this.group;
    },
    setGroup(val) {
      this.group = val;
    },
    reset() {
      this.phase = 1;
      this.client_id = undefined;
      this.employee_id = undefined;
      this.product_id = undefined;
      this.entity_id = undefined;
      this.quote_time = undefined;
      this.commentary = undefined;
      this.duration = undefined;
      // this.$refs.selEmp.reset();
      // this.$refs.selCli.reset();
      this.horary = [];
    },
    setComment(comment) {
      this.commentary = comment;
    },

    getSchedule() {
      ServiceFactory.scheduleTimes({
        ini_date: this.ini_time,
        end_date: this.end_time,
        employee_id: this.employee_id,
        group: this.group,
        prevent_duration: this.preventDuration
      }).then(res => {
        this.horary = [];
        for (let i = 0; i < 7; i++) {
          this.horary.push(res.filter(x => x.dayOfWeek == i && x.free));
        }
      });
    },

    setPreventDuration(duration) {
      this.preventDuration = duration;
    }
  }
};
</script>

<style></style>
